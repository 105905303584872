import actions from "./actions";

const {FETCH_PAYMENTS} = actions;
const initState = {
    payments: {
        page: 0,
        perPage: 10,
        data: [],
        total: 0
    }
};

const PaymentsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PAYMENTS:
            return {
                ...state,
                payments: data
            }
        default:
            return state;
    }
}

export default PaymentsReducer;
